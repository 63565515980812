.progressCarousel {
  position: relative;
  display: flex;
  align-items: top;
  justify-content: center;
  height: 220px;
  margin: 0 auto;
  padding: 32px 24px;
}

.progressCarousel-slider {
  width: 100%;
} 

.progressCarousel-buttons {
  position: absolute;
  bottom: -4px;
}

.progressCarousel [type="button"] {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  color: #aaa;
  background-color: transparent;
  border: none;
  padding: 8px 4px;
}

.progressCarousel [type="button"]:hover {
  color: #bbb;
}

.progressCarousel .carousel__back-button {
  margin-right: 24px;
}

.progressCarousel [type="button"]:disabled {
  /* value of color.yellow in theme.js */
  color: #b19d2d; 
}

.progressCarousel [type="button"]:disabled::after {
  display: inline;
  content: "\2022";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  font-size: 20px;
}